// const DEV_HOST = "https://dev-king-service.movoui.net";
const PROD_HOST = "https://king-service.movoui.net";

const HOST = PROD_HOST;
const ADD_USER = "/user/bind";
const REMOVE_USER = "/user/unbind";
const USER_LOGIN = "/admin/user/login";
const USER_LIST = "/user/bind/list";
const IG_USER_INFO = "/ig/user/info";
const UPDATE_COINS = "/ig/user/coins/add";
const USER_ORDER_LIST = "/ig/user/order/list";
const USER_ORDER_DELETE = "/ig/user/order/delete";
const APP_LIST = "/ig/app/list";
const USER_MIGRATION = "/ig/user/migration";

export { HOST, ADD_USER, REMOVE_USER, USER_LOGIN, USER_LIST, IG_USER_INFO, UPDATE_COINS, USER_ORDER_LIST, USER_ORDER_DELETE, APP_LIST, USER_MIGRATION };
