const router: Array<any> = [
  {
    path: "/",
    component: 'baseInfo/BaseInfo',
    exact: true
  },
  {
    path: "/coins",
    component: 'updateCoins/UpdateCoins'
  },
  {
    path: "/orders",
    component: 'userOrderList/OrderList'
  },
  {
    path: "/app-transfer",
    component: 'appTransfer/AppTransfer'
  },
];

export default router;
