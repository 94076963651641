import config from './config'

export default {
  requestBaseParams: {
    app: {
      bundle_id: config.bundle_id,
      app_version: config.app_version,
      device_id: config.device_id
    }
  },
  appList: [
    {appName: 'Best Likes Instagram Mate', bundleId: 'com.BestLikesInstagramMate.zengjie'},
    {appName: 'Get Likes Photo for Instagram', bundleId: 'com.GetLikesPhotoforInstagram'},
    {appName: 'Likes Templator for Instagram', bundleId: 'com.LikesTemplatorforInstagram'},
    {appName: 'Likes Analyzer for Insta Trend', bundleId: 'com.li.analyzer'},
    {appName: 'Super Portrait Camera', bundleId: 'com.yitingwang.portrait.cam'},
    {appName: 'Pop Liked Stickers for Photos', bundleId: 'com.mobileApp.photoEditor'},
    {appName: 'Get Likes Collage', bundleId: 'com.like.photocollage'},
    {appName: 'Pop Likes for Instagram Stats', bundleId: 'com.topLikesStats'},
    {appName: 'Super Likes for Insta Report', bundleId: 'com.superLikesReport'},
    {appName: 'Top Likes for Instagram Story', bundleId: 'com.TopLikesForInstagramST'},
    {appName: 'Get Likes Instagram Meter', bundleId: 'com.GetLikesInstagramMeter'},
    {appName: 'Layout for Instagram Likes Pic', bundleId: 'com.LayoutForInstagramLikesPicz'},
    {appName: 'Get Likes Pal for Instagram', bundleId: 'com.GetLikesPalforInstagram.ioszj'},
    {appName: 'Get Likes Instagram Partner', bundleId: 'com.GetLikesInstagramPartnerz.zios'}
  ]
}